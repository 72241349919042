.fade-enter,
.fade-appear {
    opacity: 0.01;
}
.fade-enter-active,
.fade-appear-active  {
    opacity: 1;
    transition: opacity 600ms ease-in;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0.01;
    transition: opacity 400ms ease-in;
}

.fontfade-enter,
.fontfade-appear {
    font-weight: 500;
}
.fontfade-enter-active,
.fontfade-appear-active  {
    font-weight: 500;
    transition: font-weight 600ms ease-in;
}
.fontfade-exit {
    font-weight: 300;
}
.fontfade-exit-active {
    font-weight: 300;
    transition: font-weight 400ms ease-in;
}